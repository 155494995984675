"use client";

import * as React from "react";
import * as ToolbarPrimitive from "@radix-ui/react-toolbar";
import { Check, ChevronRight, Circle } from "lucide-react";

import { cn } from "@acme/utils";

const ToolbarRoot = ToolbarPrimitive.Root;

const ToolbarSeparator = ToolbarPrimitive.Separator;

const ToolbarToggleGroup = ToolbarPrimitive.ToggleGroup;

const ToolbarToggleItem = ToolbarPrimitive.ToggleItem;

const Toolbar = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <ToolbarPrimitive.Root
    ref={ref}
    className={cn(
      "flex h-10 items-center space-x-1 rounded-md border bg-background p-1",
      className,
    )}
    {...props}
  />
));
Toolbar.displayName = ToolbarPrimitive.Root.displayName;

export {
  Toolbar,
  ToolbarRoot,
  ToolbarToggleGroup,
  ToolbarSeparator,
  ToolbarToggleItem,
};
